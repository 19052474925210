import React from "react";
import { Menu } from "antd";
import { useEffect, useState } from "react";
import { useLocation } from "../../../node_modules/react-router-dom";
import { CiMenuBurger } from "react-icons/ci";
export const SideBar = ({ menu, setCollapsed, collapsed }) => {
    const [active, setActive] = useState("");
    const location = useLocation();
    useEffect(() => {
        setActive(location.pathname.split("/")[1]);
    }, [location.pathname]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { textAlign: "center", marginTop: "15px" } },
            React.createElement(CiMenuBurger, { strokeWidth: 2, onClick: () => setCollapsed(!collapsed), cursor: "pointer", color: "blue" })),
        React.createElement(Menu, { style: { backgroundColor: "#f5f5f5" }, selectedKeys: [active], defaultSelectedKeys: [active], theme: "light", mode: "inline", items: menu })));
};
