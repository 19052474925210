import React from "react";
import { Flex, Typography, Popover } from "antd";
const { Text } = Typography;
const TextStyle = {
    color: "rgb(119, 117, 135)",
};
const MenuItem = ({ imgSrc, altText, text, url, permissionKey, permissionValue, hasPermission }) => {
    const isAllowed = hasPermission ? hasPermission(permissionKey, permissionValue) : true;
    return (React.createElement(Popover, { content: isAllowed ? text : "Permission Denied" },
        React.createElement(Flex, { style: isAllowed ? {} : { cursor: "not-allowed" }, onClick: () => (isAllowed ? (window.location.href = url) : null), vertical: true, align: "center", className: "dot-menu-icons" },
            React.createElement("img", { style: isAllowed ? {} : { filter: "grayscale(1)" }, src: imgSrc, alt: altText }),
            React.createElement(Text, { style: TextStyle }, text))));
};
export default MenuItem;
