import React from "react";
import { Flex, Typography, Popover } from "antd";
const { Text } = Typography;

interface MenuItemProps {
  imgSrc: string;
  altText: string;
  text: string;
  url: string;
  permissionKey: string;
  permissionValue: string[];
  hasPermission: ((key: string, value: string[]) => boolean) | null;
}

const MenuItem: React.FC<MenuItemProps> = ({ imgSrc, altText, text, url, permissionKey, permissionValue, hasPermission }) => {
  const isAllowed = hasPermission ? hasPermission(permissionKey, permissionValue) : true;

  return (
    <Flex
      vertical={true}
      align="center"
      onClick={() => {
        if (isAllowed) window.location.href = url;
      }}
      style={isAllowed ? {} : { cursor: "not-allowed" }}
      className="home-menu-icons"
    >
      <Popover content={isAllowed ? text : "Permission denied contact admin"}>
        <Flex vertical align="center">
          <img src={imgSrc} alt={altText} style={isAllowed ? {} : { filter: "grayscale(1)" }} />
          <Text style={isAllowed ? {} : { color: "grey" }}>{text}</Text>
        </Flex>
      </Popover>
    </Flex>
  );
};

export default MenuItem;
