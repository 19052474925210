import { useEffect, useState } from "react";
const useAddContactFormHandler = (form, defaultValues, initialNumbers) => {
    const initialContactData = {
        isPrivate: defaultValues?.is_private || false,
        phoneNumbers: defaultValues && defaultValues?.phones.map((phone) => phone.phone_number).length > 0 ? defaultValues?.phones.map((phone) => phone.phone_number) : initialNumbers ? initialNumbers : [""],
        emailAddresses: defaultValues && defaultValues?.emails.map((email) => email.email).length > 0 ? defaultValues?.emails.map((email) => email.email) : [""],
    };
    useEffect(() => {
        if (defaultValues) {
            form.setFieldsValue({
                first_name: defaultValues.first_name,
                middle_name: defaultValues.middle_name,
                last_name: defaultValues.last_name,
            });
        }
    }, []);
    const [isPrivateContact, setIsPrivateContact] = useState(initialContactData.isPrivate);
    const [numbers, setNumbers] = useState(initialContactData.phoneNumbers);
    const [emails, setEmails] = useState(initialContactData.emailAddresses);
    const handleAddNumber = () => {
        setNumbers([...numbers, ""]);
    };
    const handleAddEmail = () => {
        setEmails([...emails, ""]);
    };
    const handleEmailChange = (index, value) => {
        const updatedEmails = [...emails];
        updatedEmails[index] = value;
        setEmails(updatedEmails);
    };
    const handleRemoveEmail = (index) => {
        const updatedEmails = [...emails];
        updatedEmails.splice(index, 1);
        setEmails(updatedEmails);
    };
    const handleNumberChange = (index, value) => {
        const updatedNumbers = [...numbers];
        updatedNumbers[index] = value;
        setNumbers(updatedNumbers);
    };
    const handleRemoveNumber = (index) => {
        const updatedNumbers = [...numbers];
        updatedNumbers.splice(index, 1);
        setNumbers(updatedNumbers);
    };
    return {
        isPrivateContact,
        setIsPrivateContact,
        numbers,
        emails,
        // isLoading,
        handleAddNumber,
        handleAddEmail,
        handleEmailChange,
        handleRemoveEmail,
        handleNumberChange,
        handleRemoveNumber
    };
};
export default useAddContactFormHandler;
