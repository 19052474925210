import { Flex, Typography } from "antd";
import Callcenterimg from "../assets/images/Call-Center.svg";
import contactsImg from "../assets/images/Contacts.svg";
import liveCallImg from "../assets/images/Live-calls.svg";
import PBXImg from "../assets/images/PBX-Portal.svg";
import ReportsImg from "../assets/images/Reports.svg";
import SMSImg from "../assets/images/SMS.svg";
import { usePermissions } from "../hooks/permissions";
import MenuItem from "../components/Menu";
const { Text } = Typography;

const TextStyle: React.CSSProperties = {
  color: "rgb(119, 117, 135)",
};

const HeadingStyle: React.CSSProperties = {
  fontSize: "30px",
  fontWeight: "700",
  lineHeight: "32px",
  marginBottom: "20px",
};

const containerStyle: React.CSSProperties = {
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
};

const iconContainer: React.CSSProperties = {
  border: "2px solid #DFE2E8",
  borderRadius: "10px",
  padding: "20px",
};
const Home = () => {
  const { hasPermission } = usePermissions();

  return (
    <>
    <Flex vertical={true} style={containerStyle}>
      <Text style={HeadingStyle}>Switch apps to avail service</Text>
      <Flex style={iconContainer} vertical={true}>
        <Flex>
          <MenuItem
            imgSrc={Callcenterimg}
            altText="TeleDash"
            text="Live Calls"
            url={process.env.REACT_APP_LIVE_CALL_URL as string}
            permissionKey="web-applications"
            permissionValue={["teledash:any"]}
            hasPermission={hasPermission}
          />
          <MenuItem
            imgSrc={contactsImg}
            altText="Call Center"
            text="Call center"
            url={process.env.REACT_APP_LIVE_CALL_URL as string}
            permissionKey="web-applications"
            permissionValue={["teledash:any"]}
            hasPermission={hasPermission}
          />
          <MenuItem
            imgSrc={liveCallImg}
            altText="SMS"
            text="SMS"
            url={process.env.REACT_APP_SMS_URL as string}
            permissionKey="web-applications"
            permissionValue={[]}
            hasPermission={null}
          />
        </Flex>
        <Flex>
          <MenuItem
            imgSrc={PBXImg}
            altText="Contacts"
            text="Contacts"
            url={process.env.REACT_APP_CONTACTS_URL as string}
            permissionKey="web-applications"
            permissionValue={[]}
            hasPermission={null}
          />
          <MenuItem
            imgSrc={ReportsImg}
            altText="PBX Portal"
            text="PBX Portal"
            url={process.env.REACT_APP_PBX_URL as string}
            permissionKey="web-applications"
            permissionValue={[]}
            hasPermission={null}
          />
          <MenuItem
            imgSrc={SMSImg}
            altText="Reports"
            text="Reports"
            url={process.env.REACT_APP_REPORTS_URL as string}
            permissionKey="web-applications"
            permissionValue={[]}
            hasPermission={null}
          />
        </Flex>
      </Flex>
    </Flex>
    </>
  );
};

export default Home;
