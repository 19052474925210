import { Button, Form, type FormProps, Input, Typography, Flex, Checkbox } from "antd";
import { useEffect, useState } from "react";
import { MdOutlineEmail } from "react-icons/md";
import { MdLockOutline } from "react-icons/md";
import useMessageToast from "../../hooks/error/errorMessage";
import { useLocation } from "react-router-dom";


const { Text } = Typography;

const HeadingStyle: React.CSSProperties = {
  fontSize: "24px",
  fontWeight: "700",
  lineHeight: "32px",
};

const subHeading: React.CSSProperties = {
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "28px",
  marginBottom: "10px",
};

const buttonStyle: React.CSSProperties = {
  color: "white",
  width: "100%",
  marginTop: "10px",
};

const formContainer: React.CSSProperties = {
  padding: "60px",
};

const forgetPasswordText: React.CSSProperties = {
  textAlign: "right",
};

type FieldType = {
  email: string;
  password: string;
};

const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

const LoginForm = ({
  setModalState,
  login
}: {
  setModalState: React.Dispatch<
    React.SetStateAction<{
      isLogin: boolean;
      isRegister: boolean;
      isForgotPassword: boolean;
      isOTP: boolean;
    }>
  >;
  login: (data: {
    email: string;
    password: string;
    return_to?: string;
    trustDevice?: boolean;
  }) => Promise<void>;
}) => {
  const [form] = Form.useForm();
  const [clientReady, setClientReady] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const { contextHolder, success, error: errorMessage } = useMessageToast();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirectUrl = searchParams.get("redirectUrl");
  const [loginLoader, setLoginLoader] = useState(false);
  useEffect(() => {
    setClientReady(true);
  }, []);

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    try {
      setLoginLoader(true);
      await login({
        email: values.email,
        password: values.password,
        trustDevice: rememberMe,
        return_to: redirectUrl ? redirectUrl : "",
      });
      setLoginLoader(false);
    } catch (error: any) {
      setLoginLoader(false);
      errorMessage(error.response.data.errors);
    }
  };

  return (
    <Flex style={formContainer} vertical={true}>
      {contextHolder}
      <Text style={HeadingStyle}>Sign In for dataphone</Text>
      <Text style={subHeading}>
        Sign In to access all the features Datephone has to offer
      </Text>
      <Form
        name="basic"
        form={form}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item<FieldType>
          name="email"
          rules={[
            {
              type: "email",
              required: true,
              message: "Please input your email!",
            },
          ]}
        >
          <Input
            prefix={<MdOutlineEmail className="site-form-item-icon" />}
            placeholder="Email Address or User Name"
          />
        </Form.Item>

        <Form.Item<FieldType>
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password
            prefix={<MdLockOutline className="site-form-item-icon" />}
            placeholder="Password"
          />
        </Form.Item>
        <Checkbox onChange={
          (event) => setRememberMe(event.target.checked)
        }>Remember Me</Checkbox>
        <div style={forgetPasswordText}>
          {/* <a
            onClick={() =>
              setModalState({
                isForgotPassword: true,
                isLogin: false,
                isRegister: false,
                isOTP: false,
              })
            }
          >
            Forget Password?
          </a> */}
        </div>
        <Form.Item shouldUpdate>
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              loading={loginLoader}
              style={{
                ...buttonStyle,
                background:
                  !clientReady ||
                    !form.isFieldsTouched(true) ||
                    !!form.getFieldsError().filter(({ errors }) => errors.length)
                      .length ||
                    loginLoader
                    ? "#9997A5"
                    : "#130D38",
              }}
              disabled={
                !clientReady ||
                !form.isFieldsTouched(true) ||
                !!form.getFieldsError().filter(({ errors }) => errors.length)
                  .length ||
                loginLoader
              }
            >
              Log in
            </Button>
          )}
        </Form.Item>
      </Form>
    </Flex>
  );
};

export default LoginForm;
