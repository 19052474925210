import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseUser } from "../../types";

export interface userState {
  data: BaseUser | null;
  nextRefresh: number | null;
}

const initialState: userState = {
  data: null,
  nextRefresh: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<BaseUser | null>) => {
      state.data = action.payload;
    },
    setNextRefresh: (state, action: PayloadAction<number | null>) => {
      state.nextRefresh = action.payload;
    },
  },
});


export const { setUser, setNextRefresh } = userSlice.actions;

export default userSlice.reducer;