import { Flex, Modal } from "antd";
import AuthModalPictire from "../../assets/images/auth-modal-picture.png";
import LoginForm from "./LoginForm";
import { useRef, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";

const modalImg: React.CSSProperties = {
  width: "100%",
  height: "100%",
  borderTopLeftRadius:"8px",
  borderBottomLeftRadius:"8px"
};

const modalContainer: React.CSSProperties = {
  padding: "0px",
  borderRadius: "10px",
};

const imageContainer: React.CSSProperties = {
  width: "50%",
};
const formContainer: React.CSSProperties = {
  width: "50%",
};
const AuthModal = ({
  open,
  setOpen,
  login
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  login:(data:{
    email: string;
    password: string;
    return_to?: string;
    trustDevice?: boolean;
  }) => Promise<void>;
}) => {
  const [modalState, setModalState] = useState({
    isLogin: true,
    isRegister: false,
    isForgotPassword: false,
    isOTP: false,
  });

  return (
    <Modal
      style={modalContainer}
      centered
      closeIcon={
        <AiFillCloseCircle
          style={{
            height: "24px",
            width: "24px",
          }}
          onClick={() => setOpen(false)}
        />
      }
      open={open}
      onCancel={() => setOpen(false)}
      width={1000}
      mask={false}
      footer={null}
    >
      <Flex>
        <div style={imageContainer}>
          <img style={modalImg} src={AuthModalPictire} alt="auth modal" />
        </div>
        <div style={formContainer}>
          {modalState.isLogin && <LoginForm login={login} setModalState={setModalState} />}
        </div>
      </Flex>
    </Modal>
  );
};

export default AuthModal;
