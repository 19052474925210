import { message } from 'antd';

const useMessageToast  = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const success = (message:string) => {
    messageApi.open({
      type: 'success',
      content: message,
    });
  };

  const error = (message:string) => {
    messageApi.open({
      type: 'error',
      content: message,
    });
  };

  const warning = (message:string) => {
    messageApi.open({
      type: 'warning',
      content: message,
    });
  };


  return {
    contextHolder,
    success,
    error,
    warning
  }
};

export default useMessageToast;