import parsePhoneNumber, { isValidPhoneNumber } from '../../../node_modules/libphonenumber-js';
export const formatPhoneNumber = (number) => {
    if (isValidPhoneNumber(number)) {
        const phoneNumber = parsePhoneNumber(number);
        return phoneNumber?.formatNational();
    }
    else {
        return number;
    }
};
export const phoneNumberValidate = (number) => {
    const phoneNumber = parsePhoneNumber(number, 'US');
    return phoneNumber?.isValid();
};
export const phoneNumberFormatE164 = (number) => {
    const phoneNumber = parsePhoneNumber(number, 'US');
    if (phoneNumber?.isValid()) {
        return phoneNumber.number;
    }
    else {
        return null;
    }
};
