var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Cookies from "js-cookie";
import { useEffect, useMemo, useRef, useState } from "react";
import io from "socket.io-client";
import { jwtDecode } from "jwt-decode";
import debugModule from "debug";
import { refresh } from "../../Apis";
var debug = debugModule('dataphone:socket');
export var useSocket = function (_a) {
    var socketUrl = _a.socketUrl, path = _a.path;
    var timeoutRef = useRef(null);
    var _b = useState(Cookies.get("sid") ? jwtDecode(Cookies.get("sid")).exp * 1000 : null), expirationTime = _b[0], setExpirationTime = _b[1];
    ;
    useEffect(function () {
        if (!expirationTime)
            return;
        if (timeoutRef.current) {
            debug("clearing timeout");
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(function () {
            refreshToken();
        }, expirationTime - Date.now() - 2000);
        return function () {
            if (timeoutRef.current) {
                debug("clearing timeout");
                clearTimeout(timeoutRef.current);
            }
        };
    }, [expirationTime]);
    var socketClient = useMemo(function () {
        debug("creating socket");
        var socket = io(socketUrl, {
            auth: {
                token: Cookies.get("sid")
            },
            path: path
        });
        socket.io.on("reconnect_attempt", function () {
            socket.auth = { token: Cookies.get("sid") };
            debug("reconnecting socket");
        });
        return socket;
    }, [path, socketUrl]);
    var refreshToken = function () { return __awaiter(void 0, void 0, void 0, function () {
        var token, decodedToken, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    debug("refreshing token %d", expirationTime);
                    token = Cookies.get('sid');
                    if (!token) return [3 /*break*/, 3];
                    decodedToken = jwtDecode(token);
                    if (!decodedToken.exp) return [3 /*break*/, 3];
                    if (!(expirationTime && decodedToken.exp * 1000 === expirationTime)) return [3 /*break*/, 2];
                    debug("getting new token from server");
                    return [4 /*yield*/, refresh()];
                case 1:
                    response = _a.sent();
                    if (response.code == '200') {
                        debug("refreshed token successfully");
                        token = Cookies.get('sid');
                        decodedToken = jwtDecode(token);
                    }
                    _a.label = 2;
                case 2:
                    if (decodedToken.exp) {
                        setExpirationTime(decodedToken.exp * 1000);
                    }
                    _a.label = 3;
                case 3:
                    if (socketClient && socketClient.connected) {
                        debug("emitting refresh token");
                        socketClient.emit("refresh-token", token);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return { socket: socketClient };
};
