import { Button, Flex, Form, Input, Select, Switch } from "antd";
import { UserOutlined } from "../../../../node_modules/@ant-design/icons";
import { MdOutlineEmail } from "../../../../node_modules/react-icons/md";
import { FaPlus } from "../../../../node_modules/react-icons/fa";
import { FaMinus } from "../../../../node_modules/react-icons/fa";
import useAddContactFormHandler from "../../../hooks/contactFormHandler";
import { formatPhoneNumber } from "../../../utils/phoneLib";
import { parsePhoneNumber } from "../../../../node_modules/libphonenumber-js";
import { getFlag } from "../../../utils/flag";
import React, { useEffect } from "react";
const formStyle = {
    padding: "20px",
    height: "500px",
    overflow: "auto",
};
const formInputStyle = {
    marginRight: "10px",
    width: "-webkit-fill-available",
};
const plusButtonStyle = {
    background: "rgb(101, 59, 250)",
};
const plusIconStyle = {
    height: "20px",
    width: "20px",
    marginTop: "2px",
    color: "white",
    cursor: "pointer",
};
;
export const AddContactForm = ({ defaultValues, onSubmit, isLoadingAddContact, categories, tags, isTagsFetching, messageApi, initialNumbers }) => {
    const [form] = Form.useForm();
    const [selectedTags, setSelectedTags] = React.useState([
        {
            category: null,
            tags: [],
        },
    ]);
    const { emails, numbers, isPrivateContact, setIsPrivateContact, handleEmailChange, handleAddEmail, handleAddNumber, handleRemoveEmail, handleRemoveNumber, handleNumberChange } = useAddContactFormHandler(form, defaultValues || null, initialNumbers || []);
    useEffect(() => {
        if (defaultValues) {
            const mappedCategories = defaultValues?.tags?.reduce((acc, selected) => {
                const tag = tags.find((t) => t.name === selected.tag);
                if (tag) {
                    let category = acc.find((c) => c.category_id === (tag.category || "uncategorized"));
                    if (!category) {
                        category = { category_id: tag.category || "uncategorized", tags: [] };
                        acc.push(category);
                    }
                    category.tags.push({
                        label: tag.category !== "uncategorized" ? tag.name : null,
                        key: tag.category !== "uncategorized" ? tag.name : null,
                        value: tag.category !== "uncategorized" ? tag.name : null,
                    });
                }
                return acc;
            }, []);
            setSelectedTags(mappedCategories.length > 0 ? mappedCategories : [{ category_id: null, tags: [] }]);
        }
    }, []);
    const handleCategoryChange = (index, category) => {
        const updatedSelectedTags = [...selectedTags];
        updatedSelectedTags[index].category = category;
        setSelectedTags(updatedSelectedTags);
    };
    const handleTagChange = (index, tags) => {
        const updatedSelectedTags = [...selectedTags];
        updatedSelectedTags[index].tags = tags;
        setSelectedTags(updatedSelectedTags);
    };
    const onFinish = (values) => {
        let contactData = {
            id: defaultValues?.id || null,
            provider_id: null,
            is_private: isPrivateContact,
            first_name: values.first_name,
            middle_name: values.middle_name,
            last_name: values.last_name,
            phones: numbers.map((num) => {
                return { phone_number: num };
            }),
            emails: emails.map((email) => {
                return { email: email };
            }),
            addresses: [],
            tags: selectedTags,
        };
        onSubmit(contactData);
    };
    return (React.createElement(Form, { style: formStyle, onFinish: onFinish, form: form },
        React.createElement(Form.Item, { name: "first_name", style: formInputStyle, rules: [{ required: true, message: "Please input your first name!" }] },
            React.createElement(Input, { size: "large", prefix: React.createElement(UserOutlined, { className: "site-form-item-icon" }), placeholder: "First Name" })),
        React.createElement(Form.Item, { name: "middle_name", style: formInputStyle, rules: [{ required: false }] },
            React.createElement(Input, { size: "large", prefix: React.createElement(UserOutlined, { className: "site-form-item-icon" }), placeholder: "Middle Name" })),
        React.createElement(Form.Item, { name: "last_name", style: formInputStyle, rules: [{ required: false }] },
            React.createElement(Input, { size: "large", prefix: React.createElement(UserOutlined, { className: "site-form-item-icon" }), placeholder: "Last Name" })),
        emails.map((email, index) => {
            return (React.createElement(Flex, null,
                React.createElement(Form.Item, { initialValue: email, key: index, name: `emails.${index}`, style: formInputStyle, rules: [{ required: false, message: "Please input your email" }] },
                    React.createElement(Input, { size: "large", value: email, onChange: (e) => handleEmailChange(index, e.target.value), prefix: React.createElement(MdOutlineEmail, { className: "site-form-item-icon" }), placeholder: "Email" })),
                index === 0 ? (React.createElement(Button, { type: "primary", onClick: handleAddEmail, style: plusButtonStyle, size: "large", shape: "circle", icon: React.createElement(FaPlus, { style: plusIconStyle }) })) : (React.createElement(Button, { type: "primary", onClick: () => handleRemoveEmail(index), style: plusButtonStyle, size: "large", shape: "circle", icon: React.createElement(FaMinus, { style: plusIconStyle }) }))));
        }),
        numbers.map((number, index) => {
            let countryFlag = getFlag("US");
            let flag = "US";
            try {
                const phoneNumber = parsePhoneNumber(number, "US");
                if (phoneNumber?.country) {
                    countryFlag = getFlag(phoneNumber.country);
                    flag = phoneNumber.country;
                }
            }
            catch (error) {
                countryFlag = getFlag("US");
                flag = "US";
            }
            return (React.createElement(Flex, null,
                React.createElement(Form.Item, { key: index, name: `phoneNumber${index}`, style: formInputStyle, initialValue: number },
                    React.createElement(Input, { placeholder: "Enter Phone Number", defaultValue: flag == "US" ? formatPhoneNumber(number.replace(/ /g, "")) : number.replace(/ /g, ""), value: flag == "US" ? formatPhoneNumber(number.replace(/ /g, "")) : number.replace(/ /g, ""), onChange: (event) => {
                            try {
                                const phoneNumber = parsePhoneNumber(event.target.value, "US");
                                if (phoneNumber?.isValid()) {
                                    handleNumberChange(index, phoneNumber.number);
                                    form.setFieldValue(`phoneNumber${index}`, flag == "US" ? phoneNumber.formatNational() : phoneNumber.number);
                                }
                                else {
                                    handleNumberChange(index, event.target.value);
                                }
                            }
                            catch (error) {
                                handleNumberChange(index, event.target.value);
                            }
                        }, addonBefore: React.createElement("img", { style: { height: "20px", width: "30px" }, src: countryFlag }) })),
                index === 0 ? (React.createElement(Button, { type: "primary", onClick: handleAddNumber, style: plusButtonStyle, size: "large", shape: "circle", icon: React.createElement(FaPlus, { style: plusIconStyle }) })) : (React.createElement(Button, { type: "primary", onClick: () => handleRemoveNumber(index), style: plusButtonStyle, size: "large", shape: "circle", icon: React.createElement(FaMinus, { style: plusIconStyle }) }))));
        }),
        selectedTags.map((tag, index) => (React.createElement(Flex, { gap: 8, style: { marginBottom: 8 } },
            React.createElement(Select, { placeholder: "Select Category", style: { flex: 1 }, size: "middle", value: tag.category || "uncategorized", options: [...categories, { name: "uncategorized", value: "uncategorized" }].map((category) => ({
                    value: category.name,
                    label: category.name,
                    disabled: selectedTags.some((t) => t.category === category.name),
                })), onChange: (value) => {
                    handleCategoryChange(index, value);
                } }),
            React.createElement(Select, { mode: tag.category == "uncategorized" || tag.category == null ? "tags" : "multiple", defaultValue: tag.tags, placeholder: "Outlined", style: { flex: 1 }, loading: isTagsFetching, value: tag.tags, labelInValue: true, onChange: (value) => {
                    handleTagChange(index, value);
                }, options: tag.category == "uncategorized" || tag.category == null
                    ? tags.filter((contactTags) => contactTags.category === null).map((tag) => ({ value: tag.name, label: tag.name }))
                    : tags.filter((contactTags) => contactTags.category === tag.category).map((tag) => ({ value: tag.name, label: tag.name })) }),
            index === 0 ? (React.createElement(Button, { type: "primary", onClick: () => setSelectedTags([...selectedTags, { category: null, tags: [] }]), style: plusButtonStyle, size: "large", shape: "circle", icon: React.createElement(FaPlus, { style: plusIconStyle }) })) : (React.createElement(Button, { type: "primary", onClick: () => {
                    const updatedSelectedTags = [...selectedTags];
                    updatedSelectedTags.splice(index, 1);
                    setSelectedTags(updatedSelectedTags);
                }, style: plusButtonStyle, size: "large", shape: "circle", icon: React.createElement(FaMinus, { style: plusIconStyle }) }))))),
        React.createElement(Form.Item, null,
            React.createElement(Switch, { checkedChildren: "Private", onChange: () => setIsPrivateContact(!isPrivateContact), unCheckedChildren: "Public", defaultChecked: isPrivateContact })),
        React.createElement(Form.Item, null,
            React.createElement(Button, { type: "primary", style: { width: "100%" }, disabled: isLoadingAddContact, loading: isLoadingAddContact, htmlType: "submit" }, defaultValues ? "Update Contact" : "Add Contact"))));
};
