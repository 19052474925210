import { useNavigate } from "react-router-dom";
import AuthModal from "../components/Auth/AuthModal";
import { useAuth } from "../context/userContext";
import { useEffect } from "react";

const Login = ({ context: { openAuthModal, setOpenAuthModal,login } }) => {

  return (
    <div>
      {openAuthModal && (
        <AuthModal login={login} open={openAuthModal} setOpen={setOpenAuthModal} />
      )}
    </div>
  );
};

export default Login;
