import { Button, Flex, Menu, MenuProps } from "antd";
import HeaderLogo from "../../assets/logo/Logo.svg";
import { useNavigate } from "react-router-dom";

const headerLogo: React.CSSProperties = {
  cursor: "pointer",
};

const headerContainer: React.CSSProperties = {
  width: "90%",
  height: "80px",
  background: "#ffffff",
  borderBottomLeftRadius: "25px",
  borderBottomRightRadius: "25px",
  padding: "22px",
  alignSelf:"center"
};

const contactUsStyle: React.CSSProperties = {
  background: "#009595",
  color: "white",
};
const menuContainer: React.CSSProperties = {
  width: "280px",
};

const menu: React.CSSProperties = {
  width: "100%",
  borderBottom:"none"
};



const Header = ({
  openAuthModal,
  setOpenAuthModal,
}: {
  openAuthModal: boolean;
  setOpenAuthModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {

  const navigate = useNavigate()
  const items: MenuProps["items"] = [
    {
      label: "Home",
      key: "mail",
      onClick: () => navigate("/")
    },
    {
      label: "About Us",
      key: "app",
    },
    {
      label: "Solutions",
      key: "SubMenu",
    },
  ];
  return (
    <Flex style={headerContainer} justify="space-between">
      <img style={headerLogo} src={HeaderLogo} alt="header logo" />
      <Flex style={menuContainer}>
        <Menu  style={menu} items={items} mode="horizontal" />
      </Flex>
      <Flex>
        <Button type="text" onClick={() => setOpenAuthModal(true)}>Login</Button>
        <Button style={contactUsStyle}>Contact Us</Button>
      </Flex>
    </Flex>
  );
};

export default Header;
