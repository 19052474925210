import { Layout } from "antd";
import { Outlet, useNavigate } from "react-router-dom";
import { ConfigProvider } from "antd";
import LoginHeader from "./LoginHeader.layout";
import backgroundImg from "../../assets/images/Bg.png";
import { useAuth } from "../../context/userContext";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { LayoutHeader } from "@data-phone/react-generic";

const logedInLayout: React.CSSProperties = {
  backgroundImage: `url(${backgroundImg})`,
  backgroundSize: "100% 100%",
  backgroundPosition: "0% 0%",
  backgroundRepeat: "no-repeat",
  height: "100vh",
};
const LayoutStyle: React.CSSProperties = {
  height: "100vh",
  background: "white",
};

const DefaultLayout = ({ openAuthModal, setOpenAuthModal, logout }: { openAuthModal: boolean; setOpenAuthModal: React.Dispatch<React.SetStateAction<boolean>>; logout: () => void }) => {
  const navigate = useNavigate();

  const { isAuthenticated } = useAuth();
  const { data: user } = useSelector((state: RootState) => state.user);


  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Rethink Sans",
        },
      }}
    >
      <Layout style={isAuthenticated ? LayoutStyle : logedInLayout}>
        {isAuthenticated && user ? (
          <LayoutHeader permissions={user?.permissions} navigate={navigate} logout={logout}  name={`${user?.firstName} ${user?.lastName}`} />
        ) : (
          <LoginHeader openAuthModal={openAuthModal} setOpenAuthModal={setOpenAuthModal} />
        )}

        <Outlet />
      </Layout>
    </ConfigProvider>
  );
};

export default DefaultLayout;
